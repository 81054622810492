import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { Bounce, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function CheckoutForm() {
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  
  const getDataFromSessionStorage = (key) => {
    try {
      const serializedData = sessionStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from sessionStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromSessionStorage("userData");
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");

          break;
        case "processing":
          setMessage("Your payment is processing.");
          setCardData([]);
          setPaymentInfo([]);
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          setCardData([]);
          setPaymentInfo([]);
          break;
        default:
          setMessage("Something went wrong.");
          setCardData([]);
          setPaymentInfo([]);
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          paymentInfo: paymentInfo,
          cardData: cardData,
        },
        redirect: "if_required",
      });

      // console.log("Error:", error);
      // console.log("PaymentIntent:", paymentIntent);

      const getCreditPoints = (amount) => {
        if (amount === 3) {
          return 1;
        }
        if (amount === 20) {
          return 10;
        }
        if (amount === 50) {
          return 100;
        }
        if (amount === 100) {
          return 150;
        }
      };

      if (error) {
        // Handle payment error
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred. Please refresh the page");
        }
      } else {
        setMessage("Payment succeeded!");
        try {
          const creditPoints =getCreditPoints(paymentIntent?.amount / 100);
          // alert(creditPoints);
          const response = await axios.post(
            "https://argosmob.uk/carloca/public/api/add/credits",
            {
              user_id: storedUserPreferences?.id,
              credits: creditPoints,
              amount: paymentIntent?.amount / 100,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          // Handle response
          console.log(response.data);
          // console.log(response);
          if (response?.data?.status) {
            setIsLoading(true);
            toast.success(
              "Payment succeeded! Credits Points Added successfully.",
              optionToast
            );
            const returnUrl = `${window.location.origin}/#/credits-success`;
            const url = `${returnUrl}?paymentInfo=${encodeURIComponent(
              JSON.stringify(paymentIntent)
            )}`;
            setTimeout(()=>{
              window.location.href = url;
            },2000)
          } else {
            toast.error(
              "Payment succeeded but there was an error storing the data.",
              optionToast
            );
          }
        } catch (error) {
          toast.error(
            "Payment succeeded but there was an error storing the data.",
            optionToast
          );
        }
      }
    } catch (error) {
      toast.error("Error confirming payment: " + error, optionToast);

      setMessage("An unexpected error occurred. Please refresh the page");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
