import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
// import SmallSearch from '../components/SmallSearch'
import Footer from "../Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";

const RejectedRequest = () => {
  const [rejectList, setRejectList] = useState([]);
  // const [contactInfo, setContactInfo] = useState()
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L"; // Lakhs
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString();
    }
  };
  function convertCcToLiters(cc) {
    if(cc>0){

      return (cc / 1000).toFixed(1);
    }else{
      return 0;
    }
  }
  const getDataFromSessionStorage = (key) => {
    try {
      const serializedData = sessionStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from sessionStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromSessionStorage("userData");

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://argosmob.uk/carloca/public/api/get/bidder/bids",
        {
          bidder_id: storedUserPreferences?.id,
        }
      );
      // console.log(response?.data, "rejeetete");
      const filteredData = response?.data.biddings.filter(
        (item) => item.status === "reject"
      );

      setRejectList(filteredData);
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Navbar />
      <div className="new_page">
        {/* <div className="main_banner "></div>
                <SmallSearch /> */}
        <div
          className="pending_unlocks all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "70vh" }}
        >
          <h3 className="py-3 fw-bold fs-5">Rejected Requests</h3>
          <div className="row">
            {rejectList?.map((car) => (
              <div
                key={car?.id}
                className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center  col-sm-6 mb-4"
              >
                <div className="card  card_filter_card shadow-lg position-relative">
                  <div
                    className="image-container "
                    style={{ position: "relative" }}
                  >
                    <div className="row h-100">
                      <div className="col-8 h-100 pe-0">
                        <img
                          src={`https://argosmob.uk/carloca/public/uploads/cars/${car?.car?.image}`}
                          className="w-100"
                          loading="lazy"
                          alt="car"
                          style={{
                            height: "100%",
                            width: "100%",
                            borderTopRightRadius: 0,
                          }}
                        />
                      </div>
                      <div className="col-4 h-100  pl-0">
                        <div className="row pl-0  grid_img">
                          {[...Array(3)].map((_, index) => {
                            const image = car?.car?.gallery[index];
                            if (image) {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: 60,
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src={`https://argosmob.uk/carloca/public/uploads/cars-gallery/${image.image}`}
                                    alt=""
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: 60,
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src="img/noimg.jpeg"
                                    alt="Placeholder"
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="d-flex justify-content-between">
                      <Link
                        to={`/cardetails?data=${encodeURIComponent(
                          CryptoJS.AES.encrypt(
                            JSON.stringify(car?.car),
                            "secret-key"
                          ).toString()
                        )}&min=${car?.car?.min_price}&max=${
                          car?.car?.max_price
                        }`}
                        className="text-dark fw-semibold"
                        style={{ fontWeight: "14px" }}
                      >
                        ({car?.car?.year}) {car?.car?.make} {car?.car?.model}
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark fw-bold">Price Range:</h6>
                      <h6 className="fw-bold price">
                        £{formatEuroAmount(car?.car?.min_price)} - £
                        {formatEuroAmount(car?.car?.max_price)}
                      </h6>
                    </div>
                    <hr className="hr" />
                    <div className="d-flex car_info justify-content-between">
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/automatic.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.transmission_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/speedometer.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {formatNumber(car?.car?.current_mileage)}
                      </h6>
                      <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/fuel.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.fuel_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/engine.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {convertCcToLiters(car?.car?.engine_capacity)}L
                      </h6>
                    </div>

                    <hr className="hr" />

                    <div className="d-flex justify-content-between">
                      <h6 className="sml text-dark">Request From:</h6>
                      <h6 className="price ">
                        {car.bidder.first_name} {car.bidder.last_name}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark">Offer from the user:</h6>
                      <h6 className="fw-bold price">£ {formatEuroAmount(car.amount)}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

       
        <Footer />
      </div>
    </>
  );
};

export default RejectedRequest;
