import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
// import SmallSearch from '../components/SmallSearch'
import About from '../About'
import HowToUse from '../components/HowToUse'
import Footer from '../Footer'
import axios from 'axios'
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Register = () => {
  const optionToast = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState('');
  const [userOTP, setUserOtp] = useState('')
  const [errors, setErrors] = useState({});
  const [processing, setProcessing] = useState(false);
  const togglePasswordVisibility = () => {
    
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const validate = () => {
    const newErrors = {};

    if (!firstName.match(/^[A-Za-z]+$/)) {
      newErrors.firstName = "First name must contain only letters";
    }

    if (!email.match(/^\S+@\S+\.\S+$/)) {
      newErrors.email = "Enter a valid email address";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
 
  const handleSignUp = () => {
    // Validate all fields
    setProcessing(true);
    var isValidate=validate();
    if (!isValidate) {
      // If any field is empty, show an error
      toast.error("All fields are required",optionToast);
      setProcessing(false);
      return;
    }

    // Check if the password and confirmPassword match
    if (password !== confirmPassword) {
      toast.error("Password does not match the confirmation password",optionToast);
      setProcessing(false);
      return;
    }


    // You can perform additional actions like sending data to a server, validation, etc.
    if (!showOtpInput) {
      sendOtpData()
    }else {
      // console.log(userOTP, otp)
      if (userOTP === parseInt(otp)) {

        saveData();
      }
      else {
        toast.error('Invalid otp',optionToast)
        setProcessing(false);
      }
    }
  };
  const sendOtpData = async () => {
    setProcessing(true);
    try {
      const response = await axios.post(
        "https://argosmob.uk/carloca/public/api/user/email/verify",
        {
          email: email,
        }
      );

      console.log(response?.data?.otp);
      if (response?.data?.status) {
        setUserOtp(response?.data?.otp)
        toast.success(response?.data?.message,optionToast)
        
        setShowOtpInput(true)
        
      }else{
        toast.error(response?.data?.message,optionToast)

      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
    setProcessing(false);
  };

  const saveData = async () => {
    setProcessing(true);
    try {
      const response = await axios.post(
        "https://argosmob.uk/carloca/public/api/register/user",
        {
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName

        }
      );
      // console.log(firstName + " " + lastName);

      console.log(response?.data);
      if (response?.data?.status) {
        toast.success(response?.data?.message,optionToast)
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setOtp('')
        setShowOtpInput(false)
        
      }else{

        toast.error(response?.data?.message,optionToast)
      }


    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
    setProcessing(false);
  };
  

  return (
    <>
      <div className="account_details">
        <Navbar />
        {/* <SmallSearch/> */}
        <section className="account_login mt-5 pt-5 pb-4 w-100">
          <div className="container  ">
            <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
              <h2 className="text-center fw-bold">Sign Up Here</h2>
              <div className="card mt-3 border-0">
                <div className="card-body py-4 px-3 px-lg-4">
                  <h4 className=" mb-3">Enter your details below to create you account</h4>
                  <form action="">
                    <div className=" position-relative">
                      <input type="text" 
                      placeholder="First Name*" 
                      className={`w-100 ${errors.firstName?"border-danger":""}`} 
                      value={firstName}
                        onChange={(e) => setFirstName(e.target.value)} />
                      <i className="fa-regular fa-user"></i>
                    </div>
                    <small className='d-block mb-4 text-danger'>{errors.firstName}</small>
                    <div className="mb-4 position-relative">
                      <input type="text" placeholder="Last Name*" 
                      className="w-100"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <i className="fa-regular fa-user"></i>
                    </div>
                    <div className=" position-relative">
                      <input type="email" placeholder="Email Address*" 
                      className={`w-100 ${errors.email?"border-danger":""}`} 
                      value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                      <i className="fa-regular fa-envelope"></i>
                    </div>
                    <small className='text-danger mb-4 d-block'>{errors.email}</small>
                    <div className=" position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password*"
                        className={`w-100 ${errors.password?"border-danger":""}`} 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <i className="fa-solid fa-lock"></i>
                      <div
                        className="position-absolute end-0 top-50 translate-middle-y"
                        style={{ cursor: "pointer", marginRight: 10 }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </div>
                    </div>
                    <small className='text-danger mb-3 d-block'>{errors.password}</small>
                    <div className=" position-relative">
                      <input  type={showPasswordConfirm ? "text" : "password"}
                        placeholder="Confirm Password*" 
                        className={`w-100 ${errors.confirmPassword?"border-danger":""}`} 
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)} />
                      <i className="fa-solid fa-lock"></i>
                      <div
                        className="position-absolute end-0 top-50 translate-middle-y"
                        style={{ cursor: "pointer", marginRight: 10 }}
                        onClick={togglePasswordVisibilityConfirm}
                      >
                        {showPasswordConfirm ? "Hide" : "Show"}
                      </div>
                    </div>
                    <small className='text-danger mb-3 d-block'>{errors.confirmPassword}</small>
                    {showOtpInput && (
                      <div className="mb-4 position-relative">
                        <input
                          type="text"
                          placeholder="Enter OTP*"
                          className="w-100"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                        <i className="fa fa-key"></i>
                      </div>
                    )}
                    <Link to='/forgetpassword' className="blue">Forgotten Password ?</Link>
                    <div>
                      <button type="button" disabled={processing} className="btn btn-primary w-100 py-3 mt-3 fw-bold" onClick={handleSignUp}>
                        {!showOtpInput ? "SIGN UP" : "VERIFY OTP"}
                      </button>
                    </div>
                    <div className="mt-3 other_media d-flex justify-content-center">
                      <span className="text-center">Already have an account ?</span>
                    </div>
                    <div className="mt-3"> <Link to='/login' className="btn btn-outline-primary w-100 py-3 fw-bold">LOGIN</Link></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <About />
        <HowToUse />
        <Footer />
      </div>
      <ToastContainer/>
    </>
  )
}

export default Register