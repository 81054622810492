import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";

import { Link } from "react-router-dom";
import Footer from "./Footer";
import Heart from "react-heart";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import axios from "axios";

// import SmallSearch from './components/SmallSearch'

const Favourites = () => {
  const optionToast = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [favCars, setFavCars] = useState([]);
  const [likeStatus, setLikeStatus] = useState({});
  const getDataFromSessionStorage = (key) => {
    try {
      const serializedData = sessionStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from sessionStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromSessionStorage("userData");
  function formatEuroAmount(amount) {
    // Convert the amount to a string and split it into whole and decimal parts
    const parts = amount.toString().split(".");

    // Add commas for thousands separators to the whole part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the whole and decimal parts with a period and return the formatted amount
    return parts[0];
  }
  const filterCars = async () => {
    try {
      const response = await axios.post(
        "https://argosmob.uk/carloca/public/api/my-favorite-cars",
        {
          user_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        //   console.log(response?.data);
        // console.log(response?.data?.data);
       const carsData=response?.data?.data;

        const initialLikeStatus = carsData.reduce((status, car) => {
          status[car?.cars?.id] = car?.isFav;
          return status;
        }, 
        {});
        // console.log(initialLikeStatus);
        setLikeStatus(initialLikeStatus);
        setFavCars(response?.data?.data);
        
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  useEffect(() => {
    filterCars();
  }, []);
  const likeDislike = async (user_id, card_id) => {
    try {
      const responsed = await axios.post(
        "https://argosmob.uk/carloca/public/api/like-dislike",
        {
          user_id: user_id,
          car_id: card_id,
        }
      );
      // console.log(responsed);
      if (responsed?.data?.status) {
        filterCars();
        toast.success(responsed?.data?.message, optionToast);
      } else {
        toast.error(responsed?.data?.message, optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const handleLike = (cardId) => {
    if (storedUserPreferences !== null) {
      setLikeStatus((prevStatus) => ({
        ...prevStatus,
        [cardId]: !prevStatus[cardId],
      }));
      likeDislike(storedUserPreferences?.id, cardId);
    } else {
      toast.warning("Please login to add favorite car!", optionToast);
    }
  };
  return (
    <>
      <Navbar />
      <div className="new_page">
        {/* <div className="main_banner "></div>
        <SmallSearch/> */}
        <div style={{minHeight:"70vh"}} className="all_listings container d-flex flex-column align-items-center justify-content-center w-100 py-5 mt-5">
          <div className="d-flex align-items-center justify-content-between w-100 mb-3 px-2">
            <h3 className="fw-bold mb-3 fs-5 ">Favourite Cars</h3>
            
          </div>

          {favCars && favCars.length > 0 ? (
            <div className="row w-100">
              {favCars.map((car) => (
                <div
                      key={car.id}
                      className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center  col-sm-6 mb-4"
                    >
                      <div className="card  card_filter_card shadow-lg position-relative">
                        <div
                          className="image-container "
                          style={{ position: "relative" }}
                        >
                          <div className="row h-100">
                            <div className="col-8 h-100 pe-0">
                              <img
                                src={`https://argosmob.uk/carloca/public/uploads/cars/${car?.cars?.image}`}
                                className="w-100"
                                loading="lazy"
                                alt="car"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderTopRightRadius: 0,
                                }}
                              />
                            </div>
                            <div className="col-4 h-100  pl-0">
                              <div className="row pl-0  grid_img">
                                {[...Array(3)].map((_, index) => {
                                  const image = car?.cars?.gallery[index];
                                  if (image) {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          height: 60,
                                          width: "100%",
                                          marginLeft: 0,
                                          paddingLeft: 0,
                                          objectFit: "fill",
                                        }}
                                        className="col-12 "
                                      >
                                        <img
                                          src={`https://argosmob.uk/carloca/public/uploads/cars-gallery/${image.image}`}
                                          alt=""
                                          loading="lazy"
                                          className="h-100 w-100"
                                        />
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          height: 60,
                                          width: "100%",
                                          marginLeft: 0,
                                          paddingLeft: 0,
                                          objectFit: "fill",
                                        }}
                                        className="col-12 "
                                      >
                                        <img
                                          src="img/noimg.jpeg"
                                          alt="Placeholder"
                                          loading="lazy"
                                          className="h-100 w-100"
                                        />
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              top: "7px",
                              left: "7px",
                              zIndex: "1",
                              height: "26px",
                              width: "26px",
                              
                            }}
                          >
                            <Heart
                              isActive={likeStatus[car?.cars?.id]}
                              onClick={() => {
                                handleLike(car?.cars?.id);
                              }}
                              animationTrigger="both"
                              inactiveColor="#00889c"
                              activeColor="#00889C"
                              animationDuration={0.2}
                            />
                          </div>
                        </div>
                        <Link to={`/cardetails?data=${encodeURIComponent(
                                  CryptoJS.AES.encrypt(
                                    JSON.stringify(car?.cars),
                                    "secret-key"
                                  ).toString()
                                )}&min=${car?.cars?.min_price}&max=${
                                  car?.cars?.max_price
                                }&isfav=true`} className="card-body pb-1">
                          <div className="d-flex justify-content-between">
                            <h6
                              className="text-dark fw-semibold"
                              style={{ fontWeight: "14px" }}
                            >
                              ({car?.cars?.year}) {car?.cars?.make} {car?.cars?.model}
                            </h6>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6 style={{fontSize:"14px"}} className="text-dark fw-bold">Price Range:</h6>
                            <h6 style={{fontSize:"14px"}} className="fw-bold price">
                              £{formatEuroAmount(car?.cars?.min_price)} - £
                              {formatEuroAmount(car?.cars?.max_price)}
                              {/* £
                              {formatEuroAmount(
                                minPrice ? minPrice : fminPrice
                              )}{" "}
                              - £
                              {formatEuroAmount(
                                maxPrice ? maxPrice : fmaxPrice
                              )} */}
                            </h6>
                          </div>
                          <hr className="hr" />
                          <div className="d-flex car_info justify-content-between">
                            <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/automatic.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {car?.cars?.transmission_type}
                            </h6>
                            <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/speedometer.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {formatEuroAmount(car?.cars?.current_mileage)}k
                            </h6>
                            <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/fuel.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {car?.cars?.fuel_type}
                            </h6>
                            <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/engine.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {car?.cars?.engine_capacity}L
                            </h6>
                          </div>

                          <hr className="hr" />

                          {car?.cars?.owner?.role === "dealer" ? (
                            <div className="mb-2 d-flex justify-content-between">
                              <div className="d-flex align-items-center ">
                                <div
                                  style={{
                                    height: "2.4rem",
                                    width: "2.4rem",
                                    border: "2px solid #ddd",
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                  }}
                                  className="me-2 text-capitalize "
                                >
                                  <img
                                    src={`https://argosmob.uk/carloca/public/uploads/users/${car?.cars?.owner?.profile}`}
                                    alt=""
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "fill",
                                    }}
                                  />
                                </div>
                                <h6 className="my-0 text-primary fs-7 text-capitalize ">
                                  {car?.cars?.owner?.first_name}{" "}
                                  {car?.cars?.owner?.last_name}
                                </h6>
                              </div>
                              <div className="d-flex align-items-center">
                                <i className="fa text-primary me-2 fa-phone"></i>
                                <a
                                  className="fs-7 text-primary"
                                  href={"tel+" + car?.cars?.owner?.phone}
                                >
                                  {car?.cars?.owner?.phone}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="mb-2 pt-3 d-flex align-items-center justify-content-between">
                              <h6 style={{fontSize:"13px"}}
                                className="text-primary   fw-medium d-flex align-items-center text-cap
                            alize  "
                              >
                                Private Seller
                              </h6>
                              {car?.cars?.owner?.phone && (
                                <div className="d-flex align-items-center">
                                  <i className="fa text-primary me-2 fa-phone"></i>
                                  <a
                                    className="fs-7 text-primary"
                                    href={"tel+" + car?.cars?.owner?.phone}
                                  >
                                    {car?.cars?.owner?.phone}
                                  </a>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="d-flex mb-2 justify-content-between  align-items-center  ">
                            <div className="d-flex align-items-center">
                              <img
                                src="./img/pin.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              <h6 style={{fontSize:"13px"}} className="text-dark mb-0 pb-0 fw-medium d-flex align-items-center text-capitalize">
                                200 Miles Aways
                              </h6>
                            </div>
                            <div >
                              <p className="text-primary fs-7 pb-0 mb-0" >
                                More <i className="fa fa-arrow-right"></i>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
              ))}
            </div>
          ) : (
            <h5 className="text-center mt-5">Favorite Cars Not Found!</h5>
          )}
        </div>

        {/* <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content rounded-0">
              <div className="modal-body py-1">
                <div className="row p-2">
                  <div className="col-7 p-1">
                    <img className="w-100 h-100" src="img/Slide1.png" alt="" />
                  </div>
                  <div className="col-5 p-1">
                    <div className="row">
                      <div className="col-12 mb-2">
                        <img className="w-100" src="img/Slide2.png" alt="" />
                      </div>
                      <div className="col-12 ">
                        <img className="w-100" src="img/Slide3.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 py-1">
                <button
                  type="button"
                  className="btn btn-secondary rounded-0"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default Favourites;
