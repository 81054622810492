import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
// import SmallSearch from '../components/SmallSearch'
import About from "../About";
import HowToUse from "../components/HowToUse";
import Footer from "../Footer";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DealerRegistration = () => {
  const navigator = useNavigate();
  const optionToast = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [processing, setProcessing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [dealershipName, setDealershipName] = useState("");
  const [dealershipAddress, setDealershipAddress] = useState("");
  const [addressFirst, setAddressFirst] = useState("");
  const [addressSecond, setAddressSecond] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postCode, setPostCode] = useState("");
  const [dealershipId, setDealershipId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [userOTP, setUserOtp] = useState("");
  const [phoneValidation, setValidationMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [errors, setErrors] = useState({});
  const phoneNumberRegex = /^\+?[0-9]{1,15}$/;

  const validatePhoneNumber = (number) => {
    if (phoneNumberRegex.test(number)) {
      if (number.length >= 10 && number.length <= 15) {
        setValidationMessage("Valid phone number");
        setMessageColor("green");
      } else {
        setValidationMessage("Phone number must be between 10 and 15 digits.");
        setMessageColor("red");
      }
    } else {
      setValidationMessage(
        'Invalid phone number. Only numeric characters and an optional leading "+" are allowed.'
      );
      setMessageColor("red");
    }
  };

  const handlePhoneNumber = (e) => {
    const { value } = e.target;
    if (/^\+?[0-9]*$/.test(value)) {
      setPhone(value);
      validatePhoneNumber(value);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const validate = () => {
    const newErrors = {};

    if (!firstName.match(/^[A-Za-z]+$/)) {
      newErrors.firstName = "First name must contain only letters";
    }

    if (!email.match(/^\S+@\S+\.\S+$/)) {
      newErrors.email = "Enter a valid email address";
    }

    if (!phone.match(/^\d{10}$/)) {
      newErrors.phone = "Phone number must be 10 digits";
    }

    if (website && !website.match(/^(https?:\/\/)?([\w\d\-_]+\.+\S{2,})+$/)) {
      newErrors.website = "Enter a valid website URL";
    }

    if (!dealershipName) {
      newErrors.dealershipName = "Dealership name is required";
    }

    if (!addressFirst) {
      newErrors.addressFirst = "Address Line 1 is required";
    }

    if (!city.match(/^[A-Za-z\s]+$/)) {
      newErrors.city = "City must contain only letters";
    }

    if (!country.match(/^[A-Za-z\s]+$/)) {
      newErrors.country = "Country must contain only letters";
    }

    if (!postCode.match(/^\d{5,6}$/)) {
      newErrors.postCode = "Enter a valid post code";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleSignUp = () => {
    setProcessing(true);
    var isValidate=validate();
    if (!isValidate) {
      // If any field is empty, show an error
      toast.error("All fields are required", optionToast);
      setProcessing(false);
      return;
    }

    if (password !== confirmPassword) {
      toast.error(
        "Password does not match the confirmation password",
        optionToast
      );
      setProcessing(false);
      return;
    }

    if (!showOtpInput) {
      sendOtpData();
    } else {
      //   console.log(userOTP, otp);
      if (userOTP === parseInt(otp)) {
        saveData();
      } else {
        setProcessing(false);
        toast.error("Invalid otp", optionToast);
      }
    }
  };
  const sendOtpData = async () => {
    setProcessing(true);
    try {
      const response = await axios.post(
        "https://argosmob.uk/carloca/public/api/user/email/verify",
        {
          email: email,
        }
      );
      if (response?.data?.status) {
        setUserOtp(response?.data?.otp);
        setShowOtpInput(true);
        toast.success(response?.data?.message, optionToast);
      } else {
        toast.error(response?.data?.message, optionToast);
      }
      console.log(response?.data?.otp);
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
    setProcessing(false);
  };

  const saveData = async () => {
    setProcessing(true);
    try {
      const response = await axios.post(
        "https://argosmob.uk/carloca/public/api/register/dealer",
        {
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          website: website,
          dealershipName: dealershipName,
          dealershipAddress: dealershipAddress,
          addressFirst: addressFirst,
          addressSecond: addressSecond,
          city: city,
          country: country,
          postcode: postCode,
          dealershipId: dealershipId,
        }
      );

      console.log(response?.data);

      if (response?.data?.status) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setWebsite("");
        setDealershipName("");
        setPhone("");
        setDealershipAddress("");
        setAddressFirst("");
        setAddressSecond("");
        setCity("");
        setCountry("");
        setPostCode("");
        setValidationMessage("");
        setMessageColor("");
        setDealershipId("");
        setOtp("");
        setShowOtpInput(false);
        toast.success(response?.data?.message, optionToast);
        setTimeout(() => {
          navigator("/login");
        }, 1500);
      } else {
        toast.error(response?.data?.message, optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
    setProcessing(false);
  };

  return (
    <>
      <div className="account_details">
        <Navbar />
        {/* <SmallSearch/> */}
        <section className="account_login mt-5 pt-5 pb-4 w-100">
          <div className="container  ">
            <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
              <h2 className="text-center fs-4 fw-bold">Dealer Registration</h2>
              <div className="card mt-3 border-0">
                <div className="card-body py-4 px-3 px-lg-4">
                  <h4 className=" mb-3 fs-5">
                    Enter you details below to create your dealer account
                  </h4>
                  <form action="">
                    <div className=" position-relative">
                      <input
                        type="text"
                        placeholder="First Name*"
                        className={`w-100 ${errors.firstName ? 'border-danger' : ''}`}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <i className="fa-regular fa-user"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.firstName}</small>
                    <div className=" position-relative">
                      <input
                        type="text"
                        placeholder="Last Name*"
                        className={`w-100 ${errors.lastName ? 'border-danger' : ''}`}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <i className="fa-regular fa-user"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.lastName}</small>
                    <div className="position-relative">
                      <input
                        type="email"
                        placeholder="Email Address*"
                        className={`w-100 ${errors.email ? 'border-danger' : ''}`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <i className="fa-regular fa-envelope"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.email}</small>
                    <div className=" position-relative">
                      <input
                        type="tel"
                        placeholder="Phone Number*"
                        className={`w-100 ${errors.phone ? 'border-danger' : ''}`}
                        value={phone}
                        onChange={handlePhoneNumber}
                      />
                      <i className="fa-solid fa-phone"></i>
                    </div>
                    <small style={{ color: messageColor }}>
                      {phoneValidation}
                    </small>
                    <div className=" mt-4 position-relative">
                      <input
                        type="url"
                        placeholder="Website Address"
                        className={`w-100 ${errors.website ? 'border-danger' : ''}`}
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                      <i className="fa-solid fa-globe"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.website}</small>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Dealership Name"
                        className={`w-100 ${errors.dealershipName ? 'border-danger' : ''}`}
                        value={dealershipName}
                        onChange={(e) => setDealershipName(e.target.value)}
                      />
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.dealershipName}</small>
                    <div className=" position-relative">
                      <input
                        type="text"
                        placeholder="Address Line 1"
                        className={`w-100 ${errors.addressFirst ? 'border-danger' : ''}`}
                        value={addressFirst}
                        onChange={(e) => setAddressFirst(e.target.value)}
                      />
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.addressFirst}</small>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Address Line 2"
                        className={`w-100 ${errors.addressSecond ? 'border-danger' : ''}`}
                        value={addressSecond}
                        onChange={(e) => setAddressSecond(e.target.value)}
                      />
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.addressSecond}</small>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="City"
                        className={`w-100 ${errors.city ? 'border-danger' : ''}`}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.city}</small>
                    <div className=" position-relative">
                      <input
                        type="text"
                        placeholder="Country"
                        className={`w-100 ${errors.country ? 'border-danger' : ''}`}
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.country}</small>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Post Code"
                        className={`w-100 ${errors.postCode ? 'border-danger' : ''}`}
                        value={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                      />
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.postCode}</small>
                    <div className="mb-4 position-relative">
                      <input
                        type="text"
                        placeholder="Dealer ID (If Known)"
                        className={`w-100`}
                        value={dealershipId}
                        onChange={(e) => setDealershipId(e.target.value)}
                      />
                      <i className="fa-solid fa-user"></i>
                    </div>
                    
                    <div className=" position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password*"
                        className={`w-100 ${errors.password ? 'border-danger' : ''}`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <i className="fa-solid fa-lock"></i>
                      <div
                        className="position-absolute end-0 top-50 translate-middle-y"
                        style={{ cursor: "pointer", marginRight: 10 }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </div>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.password}</small>
                    <div className=" position-relative">
                      <input
                        type={showPasswordConfirm ? "text" : "password"}
                        placeholder="Confirm Password*"
                        className={`w-100 ${errors.confirmPassword ? 'border-danger' : ''}`}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <i className="fa-solid fa-lock"></i>
                      <div
                        className="position-absolute end-0 top-50 translate-middle-y"
                        style={{ cursor: "pointer", marginRight: 10 }}
                        onClick={togglePasswordVisibilityConfirm}
                      >
                        {showPasswordConfirm ? "Hide" : "Show"}
                      </div>
                    </div>
                    <small className="text-danger mb-4 d-block">{errors.confirmPassword}</small>
                    {showOtpInput && (
                      <div className="mb-4 position-relative">
                        <input
                          type="text"
                          placeholder="Enter OTP*"
                          className="w-100"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                        <i className="fa fa-key"></i>
                      </div>
                    )}
                    <Link to="/forgetpassword" className="blue">
                      Forgotten Password ?
                    </Link>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary w-100 py-3 mt-3 fw-semibold"
                        onClick={handleSignUp}
                        disabled={processing}
                      >
                        {!showOtpInput ? "SIGN UP" : "VERIFY OTP"}
                      </button>
                    </div>
                    <div className="mt-3 other_media d-flex justify-content-center">
                      <span className="text-center">
                        Already have an account ?
                      </span>
                    </div>
                    <div className="mt-3">
                      {" "}
                      <Link
                        to="/login"
                        className="btn btn-outline-primary w-100 py-3 fw-bold"
                      >
                        LOGIN
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <About />
        <HowToUse />
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default DealerRegistration;
