import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

// import required modules
import { Navigation, Pagination } from "swiper/modules";

const CarDetailSlider = ({ data, image }) => {
  // console.log(data, "data")
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        navigation
        autoplay={{
          delay: 2500,
        }}
        modules={[Pagination, Navigation]}
        pagination={{ clickable: true }}
        className="mySwiper"
      >

        

        {data?.length > 0 ? (
          data.map((slide) => (
            
            <SwiperSlide key={slide.id}>
              <img
                className="slide-image rounded-3"
                alt=""
                src={`https://argosmob.uk/carloca/public/uploads/cars-gallery/${slide.image}`}
                style={{ height: "auto", width: "100%" }}
              />
              <div className={slide.className}></div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <img
              className="slide-image"
              alt=""
              src={`https://argosmob.uk/carloca/public/uploads/dummy.png`}
              style={{ height: "auto", width: "100%" }}
            />
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
};

export default CarDetailSlider;
